import { useState, useCallback, useEffect, useRef } from 'react'

export const CONTENT_SOURCE_ID = 'content-source'

/**
 * 위로 스크롤이 될 때만 특정 컴포넌트를 노출하기 위한 커스텀 훅
 * 추가로, 컨텐츠의 출처 영역이 뷰포트에 노출되는 시점부터는 무조건 툴바를 노출한다.
 * @returns isVisible `boolean` 노출되어야할지 여부
 */
const useScrollUpVisible = () => {
  const [isVisible, setIsVisible] = useState(true)
  const lastScrollY = useRef(0)

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY
    const element = document.getElementById(CONTENT_SOURCE_ID)

    // 출처 영역이 뷰포트에 보이는지 여부
    const isSourceInView =
      element && element.getBoundingClientRect().top < window.innerHeight

    // 위로 스크롤하거나, 뷰포트에 출처 영역이 노출되기 시작한 경우 툴바를 노출
    if (currentScrollY <= lastScrollY.current || isSourceInView)
      setIsVisible(true)
    else setIsVisible(false)

    lastScrollY.current = currentScrollY
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return { isVisible, setIsVisible }
}

export default useScrollUpVisible
