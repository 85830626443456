import useSWR from 'swr'
import { API_PATH, MESSAGES } from '@/consts'
import BoardUpdate from '@/containers/BoardView/BoardUpdate'
import { useModal } from '@/hooks/useModal'
import useFullScreenConfirmableModal from '@/hooks/useModal/useFullScreenConfirmableModal'
import { BoardResponse } from '@/types'
import MaterialSymbol from '@/v1/MaterialSymbol'

const EditButton = ({ id }: { id: string }) => {
  const { alertModal } = useModal()
  const { modal: fullScreenConfirmableModal } = useFullScreenConfirmableModal()
  const { data } = useSWR<BoardResponse>(
    `${API_PATH.Qna}${id}/?render_type=html`,
  )
  const statusInProgress = data?.status !== 'WAITING'

  const handleButtonClick = () => {
    if (statusInProgress)
      alertModal.show({
        message: MESSAGES.EDIT_ALERT_TEXT,
      })
    else
      fullScreenConfirmableModal.show({
        title: '질문 수정',
        gaCloseSelector: 'board_update_close',
        children: <BoardUpdate id={id} />,
        confirmMessage:
          '질문 수정을 종료하겠습니까?\n종료하면 수정한 내용이 삭제됩니다.',
      })
  }

  if (statusInProgress) return null

  return (
    <>
      <span className="text-grey-200 px-2">|</span>
      <button
        data-ga="board_document_update"
        type="button"
        onClick={handleButtonClick}
        className="flex items-center text-lavendar-500"
      >
        <MaterialSymbol
          name="edit_fill"
          size={16}
          className="fill-lavendar-500"
        />
        <span className="text-sm ml-1.5">수정</span>
      </button>
    </>
  )
}
export default EditButton
