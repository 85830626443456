import { DateTime } from 'luxon'
import { DateText } from '@/components/DateText'

export default function CreatedDate({ createdAt }: { createdAt: string }) {
  const daytime = DateTime.fromISO(createdAt).setZone('Asia/Seoul')
  const yyyyMmDd = daytime.toFormat('yyyy.MM.dd.')

  return (
    <DateText
      className="w-full text-sm text-gray-70 mt-2 mb-4"
      text={yyyyMmDd}
    />
  )
}
