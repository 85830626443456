import { LoginPromotionModalProps } from './LoginPromotionModal'

type LoginPromotionModalContentType = Omit<
  LoginPromotionModalProps,
  'redirectPath'
>

export const BOARD_FULL_MODAL_CONTENT: LoginPromotionModalContentType = {
  heading1: <span className="text-lavendar-40">암 전문 의료팀에게</span>,
  heading2: '언제든 물어볼 수 있습니다',
  body: `3초만에 로그인 하고
  24시간 안에 답변 받아 보세요.`,
  gaSelectorKakao: 'signup_kakao',
  gaSelectorNaver: 'signup_naver',
  gaSelectorCancel: 'signup_back',
  imgSrc: '/assets/images/board_question_image.png',
}

export const HEATHINFO_FULL_MODAL_CONTENT: LoginPromotionModalContentType = {
  heading1: '건강 기록을 작성하고,',
  heading2: <span className="text-lavendar-40">맞춤 답변을 받아 보세요</span>,
  body: `작성된 건강 기록은 환자 상태에 맞는
  맞춤 답변을 작성하는 데만 사용됩니다.`,
  gaSelectorKakao: 'healthinformation_signup_kakao',
  gaSelectorNaver: 'healthinformation_signup_naver',
  gaSelectorCancel: 'healthinformation_signup_back',
  imgSrc: '/assets/images/healthInfo_survey_image_blue.png',
}
