import { useModal } from '@ebay/nice-modal-react'
import eventEmitter, { EVENT } from '@/utils/eventEmitter'
import Modal from './Modal'

/**
 * 닫을 때 cocnfirm 창을 띄우는 풀스크린 모달을 사용하기 위한 훅
 * @returns modal
 */
const useFullScreenConfirmableModal = () => {
  const modal = useModal(Modal)

  return {
    modal,
  }
}

/**
 * 풀스크린 모달을 닫을 때, confirm 창을 띄울지 여부를 체크하는 함수
 * @param shouldConfirm confirm 창을 띄울지 여부
 * @param onClose confirm 창을 띄운 후 실행할 함수
 */
export const checkCloseConfirmation = (
  shouldConfirm: boolean,
  onClose?: () => void,
) => {
  eventEmitter.emit(EVENT.CHECK_CONFIRMATION, {
    shouldConfirm,
    onClose,
  })
}

export default useFullScreenConfirmableModal
