import { PropsWithChildren, forwardRef } from 'react'
import clsx from 'clsx'
import { filterPropsWithPrefix } from '@/utils/filterPropsWithPrefix'
import MaterialSymbol from '../MaterialSymbol'
import { BUTTON_STYLE, SIZE_CONFIG } from './styles'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'sm' | 'md' | 'lg'
}

const CheckboxButton = forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  ({ variant = 'sm', children, ...rest }, ref) => {
    const dataProps = filterPropsWithPrefix(rest, 'data-')

    return (
      <label
        className={clsx('inline-block', 'w-full', 'group', rest.className)}
      >
        <input
          type="checkbox"
          className="peer opacity-0 absolute"
          defaultChecked={rest.defaultChecked}
          name={rest.name}
          value={rest.value}
          onChange={rest.onChange}
          disabled={rest.disabled}
          checked={rest.checked}
          {...dataProps}
          ref={ref}
          {...dataProps}
        />
        <span className={clsx(BUTTON_STYLE, SIZE_CONFIG[variant])}>
          <span>{children}</span>
          <span
            className={clsx(
              'sub-label',
              'border',
              'border-grey-400',
              'w-5 h-5',
              'rounded',
              'flex',
              'items-center',
              'justify-center',
              'bg-white',
              'group-has-[:checked]:bg-lavendar-500',
              'group-has-[:checked]:border-lavendar-500',
              'group-has-[:disabled]:bg-grey-200',
              'group-has-[:disabled]:border-grey-100',
            )}
          >
            <MaterialSymbol
              name="check"
              className="fill-white group-has-[:disabled]:fill-grey-200"
            />
          </span>
        </span>
      </label>
    )
  },
)

CheckboxButton.displayName = 'CheckboxButton'
export default CheckboxButton
