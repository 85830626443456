import { PropsWithChildren, useEffect, useRef } from 'react'
import clsx from 'clsx'

const BACKGROUND_COLOR_CONFIG = {
  transparent: 'bg-transparent',
  white: 'bg-white',
}

const HorizontalScrollable = ({
  children,
  leftOffset,
  backgroundColor = 'white',
}: PropsWithChildren<{
  leftOffset?: number
  backgroundColor?: 'transparent' | 'white'
}>) => {
  const ref = useRef<HTMLDivElement>(null)

  // leftOffset으로 스크롤된다
  useEffect(() => {
    if (!ref.current || leftOffset === undefined) return
    ref.current.scrollTo({
      left: leftOffset,
      behavior: 'smooth',
    })
  }, [leftOffset])

  // 마우스로 스크롤 가능하게 한다
  useEffect(() => {
    const tab = ref.current
    if (!tab) return
    let isMouseDown = false
    let isDragged = false
    let start = 0
    let scrollLeft = 0

    const preventClick = (e: MouseEvent) => {
      e.stopPropagation()
    }

    const handleMouseDown = (e: MouseEvent) => {
      isMouseDown = true
      start = e.pageX - tab.offsetLeft // 마우스 시작한 위치
      scrollLeft = tab.scrollLeft
    }

    const handleMouseLeave = () => {
      isMouseDown = false
    }

    const handleMouseUp = () => {
      if (isDragged) tab.addEventListener('click', preventClick)
      else tab.removeEventListener('click', preventClick)

      isDragged = false
      isMouseDown = false
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (!isMouseDown) return
      const currentX = e.pageX - tab.offsetLeft // 현재 마우스 위치
      const moveX = currentX - start // 마우스로 움직인 거리
      tab.scrollLeft = scrollLeft - moveX

      if (Math.abs(moveX) > 10) isDragged = true
    }

    tab.addEventListener('mousedown', handleMouseDown)
    tab.addEventListener('mouseleave', handleMouseLeave)
    tab.addEventListener('mouseup', handleMouseUp)
    tab.addEventListener('mousemove', handleMouseMove)
  }, [ref])

  return (
    <div
      className={clsx(
        'scrollbar-hide',
        'cursor-grab',
        'whitespace-nowrap',
        'overflow-x-scroll',
        'select-none',
        BACKGROUND_COLOR_CONFIG[backgroundColor],
        'w-full',
      )}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default HorizontalScrollable
