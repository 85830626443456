'use client'

import { useSetAtom } from 'jotai'
import { useCreateBoardModal } from '@/hooks/useCreateBoardModal'
import useFullScreenConfirmableModal from '@/hooks/useModal/useFullScreenConfirmableModal'
import { aiQuestionAtom, INITIAL_AI_QUESTION } from '@/stores'
import Nudge from '@/v1/Nudge'
import BoardCreateByAI from './BoardCreateByAI'

/**
 * AI 추천 질문 열기 버튼
 * @returns
 */
const AIQuestionNudge = () => {
  const { modal: fullScreenConfirmableModal } = useFullScreenConfirmableModal()
  const { handleCreateBoardAuth } = useCreateBoardModal()
  const setAiQuestion = useSetAtom(aiQuestionAtom)

  return (
    <div className="p-md">
      <h2 className="prose-h5 mb-4">AI 추천 질문</h2>
      <Nudge
        data-ga="board_create_try"
        onClick={async () => {
          fullScreenConfirmableModal.show({
            title: 'AI 추천 질문 선택하기',
            gaBackSelector: 'board_create_ai_back',
            children: <BoardCreateByAI />,
            confirmMessage:
              '질문을 종료하겠습니까?\n종료하면 작성한 내용이 삭제됩니다.',
            onBack: () => {
              fullScreenConfirmableModal.hide()
              handleCreateBoardAuth()
              setAiQuestion(INITIAL_AI_QUESTION) // 초기화
            },
          })
        }}
      >
        질문이 떠오르지 않으신다면
        <br />
        <b>AI에게 추천</b>을 받아보세요!
      </Nudge>
    </div>
  )
}

export default AIQuestionNudge
