import clsx from 'clsx'
import { BadgeLayoutStyle } from '../style'
import { LabelBadgeStyleByVariant } from './style'

export type LabelBadgeVariants = 'filled' | 'outlined'
export type LabelBadgeColor = 'black' | 'grey' | 'aqua' | 'lavendar'

export type LabelBadgeProps = {
  variant?: LabelBadgeVariants
  label: string
  color?: LabelBadgeColor
}

const LabelBadge = ({
  variant = 'outlined',
  label,
  color = 'grey',
}: LabelBadgeProps) => {
  return (
    <span
      className={clsx(
        BadgeLayoutStyle,
        LabelBadgeStyleByVariant[variant].color[color],
        'prose-p4',
      )}
    >
      {label}
    </span>
  )
}

export default LabelBadge
