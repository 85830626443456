export const LabelBadgeStyleByVariant = {
  filled: {
    color: {
      black: 'text-white bg-grey-800',
      grey: 'text-grey-800 bg-grey-200',
      aqua: 'text-white bg-aqua-500',
      lavendar: 'text-lavendar-600 bg-lavendar-100',
    },
  },
  outlined: {
    color: {
      black: 'text-grey-800 bg-white border-[1px] border-grey-600',
      grey: 'text-grey-600 bg-white border-[1px] border-grey-400',
      aqua: '',
      lavendar: '',
    },
  },
} as const
