import useSWR from 'swr'
import { SWRConfiguration } from 'swr/_internal'
import { API_PATH } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { handleSWR } from '@/utils/httpClient/handleSWR'
import type { HealthInformation } from './types'

export type { HealthInformation } from './types'

/**
 * 내 건강기록 ('/api/me/health-information') 요청 후 response 반환
 */
const useMyHealthInfo = (config?: SWRConfiguration) => {
  const { isLoggedIn } = useAuth()
  // 로그인 상태가 아니면 요청을 보내지 않음
  const { data, isLoading, mutate, error } = useSWR<HealthInformation>(
    isLoggedIn ? API_PATH.MyHealthInformation : null,
    handleSWR,
    config && config,
  )
  return { myHealthInfo: data, isLoading, mutate, error }
}

export default useMyHealthInfo
