import clsx from 'clsx'
import { textButtonStyle, textUnderlineStyle } from '@/v1/TextButton/styles'
import type { TextButtonProps } from '@/v1/TextButton/types'

const TextButton = ({
  type = 'button',
  size = 'large',
  color = 'plane',
  block,
  leftIcon,
  rightIcon,
  disabled: isDisabled,
  children,
  ...restProps
}: TextButtonProps) => {
  const isTextUnderline = color === 'underline'

  return (
    <button
      type={type}
      className={clsx(
        textButtonStyle.layout,
        { 'w-full': !block },
        textButtonStyle.size[size],
        isDisabled ? textButtonStyle.disabled : textButtonStyle.color[color],
        { [textUnderlineStyle]: !isDisabled && isTextUnderline },
      )}
      disabled={isDisabled}
      {...restProps}
    >
      {leftIcon}
      <span className="pointer-events-none">{children}</span>
      {rightIcon}
    </button>
  )
}

export default TextButton
