import mitt from 'mitt'

export const EVENT = {
  AUDIO_PAUSE: 'AUDIO_PAUSE',
  CHECK_CONFIRMATION: 'CHECK_CONFIRMATION',
  // 메인 암종 드롭다운 옆 툴팁 노출을 수동으로 관리
  SET_MAIN_TOOLTIP_MANUALLY: 'SET_MAIN_TOOLTIP_MANUALLY',
} as const

export type Events = {
  [EVENT.AUDIO_PAUSE]: undefined
  [EVENT.CHECK_CONFIRMATION]: { shouldConfirm: boolean; onClose?: () => void }
  [EVENT.SET_MAIN_TOOLTIP_MANUALLY]: boolean
}

const eventEmitter = mitt<Events>()

export default eventEmitter
