export const BUTTON_STYLE = [
  'flex',
  'items-center',
  'justify-between',
  'bg-white',
  'border border-grey-200',
  'rounded-lg',
  'prose-p2',
  'px-4',
  'select-none',
  'cursor-pointer',
  'font-medium',
  'peer-checked:font-bold',
  'peer-checked:bg-lavendar-100',
  'peer-checked:border-lavendar-200',
  'peer-disabled:bg-grey-100',
  'peer-disabled:text-grey-400',
  'peer-disabled:cursor-not-allowed',
  'peer-focus-visible:border-lavendar-500',
].join(' ')

export const SIZE_CONFIG = {
  sm: 'py-2',
  md: 'py-3',
  lg: 'py-5',
}
