import { useEffect, useRef } from 'react'
import { useAtomValue } from 'jotai'
import { Divider } from '@/components/Divider'
import { aiQuestionAtom } from '@/stores'
import MaterialSymbol from '@/v1/MaterialSymbol'
import useAIQuestionRequest from '../useAIQuestionRequest'
import { getPreviousFormData } from '../utils'

/**
 * AI 추천 질문 헤더
 */
const Header = () => {
  const domRef = useRef<HTMLDivElement>(null)
  const { questions, state } = useAtomValue(aiQuestionAtom)
  const { request } = useAIQuestionRequest()
  const previousFormData = getPreviousFormData()

  const handleClick = () => {
    if (previousFormData) {
      request(previousFormData)
    }
  }

  useEffect(() => {
    if (!questions.length && state === 'default') return
    // 스크롤 이동
    domRef.current?.scrollIntoView()
  }, [questions.length, state])

  return (
    <div ref={domRef}>
      <Divider className="w-full border-grey-100 border-t-[10px]" />
      <div className="px-md">
        <div className="flex justify-between items-center w-full">
          <h3 className="prose-h5 pt-md pb-4">AI 추천 질문</h3>
          <button
            type="button"
            data-ga="board_create_retry"
            className="flex items-center prose-p3 text-lavendar-500 font-medium pt-md pb-4"
            onClick={handleClick}
          >
            <MaterialSymbol
              name="refresh"
              size={16}
              className="mr-1 fill-lavendar-500"
            />
            다시 추천받기
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
