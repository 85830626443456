'use client'

import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useForm, Controller } from 'react-hook-form'
import ReactTextareaAutosize from 'react-textarea-autosize'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import { checkCloseConfirmation } from '@/hooks/useModal/useFullScreenConfirmableModal'
import useMyHealthInfo from '@/hooks/useMyHealthInfo'
import { aiQuestionAtom, INITIAL_AI_QUESTION } from '@/stores'
import Button from '@/v1/Button'
import CheckboxButton from '@/v1/SelectButton/CheckboxButton'
import RadioButton from '@/v1/SelectButton/RadioButton'
import {
  KOR_TO_ENG_CANCERS,
  KOR_TO_ENG_STATUSES,
  KOR_TO_ENG_TREATMENTS,
} from '../consts'
import type { AIRecommendationForm } from '../types'
import useAIQuestionRequest from './useAIQuestionRequest'

// 여정이 여기 포함되어 있으면, 받은/예정된 치료 항목을 보여준다.
const HAS_TREATMENT = ['DIAGNOSIS', 'TREATMENT', 'MANAGEMENT']

/**
 * AI 질문 생성 form
 */
const AIRecommendationsForm = () => {
  const { myHealthInfo } = useMyHealthInfo({
    revalidateOnFocus: false,
  })
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    register,
    setValue,
    watch,
  } = useForm<AIRecommendationForm>({
    mode: 'onChange',
    values: {
      status: myHealthInfo?.status
        ? KOR_TO_ENG_STATUSES[
            myHealthInfo.status as keyof typeof KOR_TO_ENG_STATUSES
          ]
        : '',
      cancer: myHealthInfo?.cancer?.name
        ? KOR_TO_ENG_CANCERS[
            myHealthInfo.cancer.name as keyof typeof KOR_TO_ENG_CANCERS
          ]
        : '',
      treatments: [],
      interest: '',
    },
  })
  const [aiQuestion, setAiQuestion] = useAtom(aiQuestionAtom)
  const { status } = watch()
  const { request } = useAIQuestionRequest()

  const onSubmit = async (formData: AIRecommendationForm) => {
    await request(formData)
    TagManager.dataLayer({
      dataLayer: {
        event: 'board_create_ai_recommend',
      },
    })
    // DOM 엘리먼트에 form 데이터 저장
    const formDataElement = document.getElementById('formData')
    if (formDataElement) {
      formDataElement.dataset.formData = JSON.stringify(formData)
    }
  }

  useEffect(() => {
    checkCloseConfirmation(isDirty, () => {
      setAiQuestion(INITIAL_AI_QUESTION)
      TagManager.dataLayer({
        dataLayer: {
          event: 'board_create_ai_close',
        },
      })
    })
  }, [isDirty, setAiQuestion])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="prose-p2 mt-md mb-3">
          <b>암 종류</b>
        </p>
        <div className="flex flex-wrap pb-2.5">
          {Object.entries(KOR_TO_ENG_CANCERS).map(([label, value], idx) => {
            return (
              <div className="flex w-1/2 mb-2.5" key={`cancer-${value}`}>
                <span
                  className={clsx('inline-block', 'w-full', {
                    'mr-[5px]': idx % 2 === 0,
                    'ml-[5px]': idx % 2 === 1,
                  })}
                >
                  <Controller
                    name="cancer"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <RadioButton
                        {...field}
                        variant="md"
                        value={value}
                        data-ga="board_create_ai_cancer"
                        onChange={(e) => {
                          field.onChange(e)
                          // 입력값이 바뀌면 submit 버튼 활성화
                          setAiQuestion(INITIAL_AI_QUESTION)
                        }}
                        checked={field.value === value}
                      >
                        {label}
                      </RadioButton>
                    )}
                  />
                </span>
              </div>
            )
          })}
        </div>
        <p className="prose-p2 mt-md mb-3">
          <b>암 여정</b>
        </p>
        <div className="flex flex-wrap">
          {Object.entries(KOR_TO_ENG_STATUSES).map(([label, value], idx) => {
            return (
              <div className="flex w-1/2 mb-2.5" key={`status-${value}`}>
                <span
                  className={clsx('inline-block', 'w-full', {
                    'mr-[5px]': idx % 2 === 0,
                    'ml-[5px]': idx % 2 === 1,
                  })}
                >
                  <Controller
                    name="status"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <RadioButton
                        {...field}
                        variant="md"
                        value={value}
                        data-ga="board_create_ai_phase"
                        onChange={(e) => {
                          if (!HAS_TREATMENT.includes(e.target.value)) {
                            // 여정에 따라 받은/예정된 치료 항목을 초기화
                            setValue('treatments', [])
                          }
                          field.onChange(e)
                          setAiQuestion(INITIAL_AI_QUESTION)
                        }}
                        checked={field.value === value}
                      >
                        {label}
                      </RadioButton>
                    )}
                  />
                </span>
              </div>
            )
          })}
          {HAS_TREATMENT.includes(status ?? '') && (
            <div>
              <p className="prose-p2 mt-md mb-3">
                <b>[선택] 받은/예정된 치료</b>
              </p>
              <fieldset className="pb-3.5">
                {Object.entries(KOR_TO_ENG_TREATMENTS).map(([label, value]) => (
                  <Controller
                    key={`care_goal-${label}`}
                    name="treatments"
                    control={control}
                    render={({ field }) => (
                      <CheckboxButton
                        {...field}
                        variant="md"
                        value={value}
                        className="mb-2.5"
                        data-ga="board_create_ai_treatment"
                        onChange={(e) => {
                          if (!value) return
                          if (e.target.checked) {
                            // 체크된 경우 배열에 추가
                            field.onChange([...(field.value ?? []), value])
                          } else {
                            // 체크 해제된 경우 배열에서 제거
                            field.onChange(
                              (field.value ?? []).filter((v) => v !== value),
                            )
                          }
                          setAiQuestion(INITIAL_AI_QUESTION)
                        }}
                      >
                        {label}
                      </CheckboxButton>
                    )}
                  />
                ))}
              </fieldset>
            </div>
          )}

          <div className="w-full mt-2.5 mb-md">
            <p className="prose-p2 mt-md mb-3">
              <b>[선택] 관심 주제</b>
            </p>
            <ReactTextareaAutosize
              className={clsx(
                'w-full',
                'py-2',
                'prose-p1',
                'font-medium',
                'border-b',
                'border-b-grey-200',
                'outline-none',
                'placeholder:text-grey-400',
                'focus:border-b-grey-400',
                'resize-none',
              )}
              data-ga="board_create_ai_topic"
              placeholder="“타목시펜, 부작용”처럼 궁금한 내용을 단어로 입력해 주세요."
              {...register('interest', {
                onChange: () => {
                  setAiQuestion(INITIAL_AI_QUESTION)
                },
              })}
            />
          </div>
          <Button
            type="submit"
            size="large"
            disabled={!isValid || aiQuestion.state !== 'default'}
          >
            AI 추천 질문 선택하기
          </Button>
        </div>
      </form>
      {/* AI 추천질문 섹션에서 사용하기 위해, form submit 데이터를 DOM 엘리먼트에 저장한다. */}
      <input type="hidden" id="formData" />
    </>
  )
}

export default AIRecommendationsForm
