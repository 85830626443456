import {
  ENG_TO_KOR_TREATMENTS,
  ENG_TO_KOR_CANCERS,
  ENG_TO_KOR_STATUSES,
} from '../consts'
import type { AIRecommendationForm } from '../types'

/**
 * AI 질문추천 요청 form submit 시, formData를 DOM 엘리먼트에 저장한다.
 * 저장된 값을 사용하여, 질문 작성 섹션에서 활용한다.
 * 상태관리를 피하기 위해, react 방식이 아닌 DOM 엘리먼트에 저장한다.
 * @returns
 */
export const getPreviousFormData = () => {
  const formDataElement = document.getElementById('formData')
  if (!formDataElement) return undefined
  if (!formDataElement.dataset.formData) return undefined

  try {
    const formData = JSON.parse(formDataElement.dataset.formData)
    return formData as AIRecommendationForm
  } catch (error) {
    return undefined
  }
}

/**
 * 유저가 입력한 정보를 템플릿 형식으로 반환한다.
 */
export const getUserDataTemplate = () => {
  const {
    cancer = '',
    status = '',
    treatments = [],
    interest,
  } = getPreviousFormData() ?? {}

  return `의료팀이 답변 작성 시 참고하는 정보
  - 암 종류: ${ENG_TO_KOR_CANCERS[cancer] ?? ''}
  - 암 여정: ${ENG_TO_KOR_STATUSES[status] ?? ''}
  - 받은/예정된 치료: ${
    treatments.map((v) => ENG_TO_KOR_TREATMENTS[v]).join(', ') || ''
  }
  - 관심 주제: ${interest || ''}`
}
