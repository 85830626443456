import { ReactNode } from 'react'
import clsx from 'clsx'
import { CONTENT_SOURCE_ID } from '@/hooks/useScrollUpVisible'

const FootNote = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <div
      id={CONTENT_SOURCE_ID}
      className={clsx(
        className,
        'px-4',
        'py-4',
        'w-full',
        'text-grey-700',
        'bg-grey-100',
        'rounded-[8px]',
        'prose-p3',
      )}
    >
      {children}
    </div>
  )
}
export default FootNote
