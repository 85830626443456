import { default as NextImage } from 'next/image'

// remotePatterns에 정의된 도메인 목록
const IMAGE_REMOTES_HOSTNAMES = [
  'static.lunit.care',
  'static-staging.lunit.care',
  'static-dev.lunit.care',
  'api.lunit.care',
  'api.s.lunit.care',
  'api.d.lunit.care',
]

const getNumericValue = (value: string | undefined) => {
  if (!value) return undefined
  const isNumeric = /^\d+$/.test(value)
  if (isNumeric) return Number(value)
  return undefined
}

const Image = (domNodeAttribs: { [name: string]: string }) => {
  const { alt = '', src, width, height } = domNodeAttribs

  try {
    const url = new URL(src)
    if (IMAGE_REMOTES_HOSTNAMES.includes(url.hostname)) {
      return (
        <NextImage
          alt={alt}
          src={src}
          width={getNumericValue(width) ?? 768} // PC의 최대 해상도는 768px이므로 너비 기본값을 768px로 설정합니다.
          height={getNumericValue(height) ?? 340} //이미지 너비와 높이 비율을 대략 2.2556으로 설정한 값입니다. 높이 기본값은 768 / 2.2556 = 340 입니다.
          suppressHydrationWarning
        />
      )
    }
    return (
      <img alt={alt} {...domNodeAttribs} loading="lazy" className=" mx-auto" />
    )
  } catch (error) {
    return (
      <img alt={alt} {...domNodeAttribs} loading="lazy" className=" mx-auto" />
    )
  }
}

const Img = (domNodeAttribs: { [name: string]: string }) => {
  const { alt } = domNodeAttribs

  if (!alt) return <Image {...domNodeAttribs} alt={alt} />

  return (
    <>
      <Image {...domNodeAttribs} alt={alt} />
      <figcaption className="mt-4 font-medium text-grey-600 text-center">
        {alt}
      </figcaption>
    </>
  )
}

export default Img
