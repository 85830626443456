import { BoardResponse } from '@/types'
import Content from './Content'

/**
 * AI로 작성된 질문 제목
 */
const Title = ({ title }: { title: BoardResponse['title'] }) => {
  return (
    <>
      <h1 className="inline-block whitespace-pre-line prose-h5 break-keep">
        <span className="text-lavendar-500">Q. </span>
        <Content content={title} />
      </h1>
    </>
  )
}

export default Title
