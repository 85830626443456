import TagManager from 'react-gtm-module'
import { FieldErrors, UseFormReset } from 'react-hook-form'
import { useSetAtom } from 'jotai'
import { API_PATH, MESSAGES } from '@/consts'
import { useModal } from '@/hooks/useModal'
import useFullScreenConfirmableModal from '@/hooks/useModal/useFullScreenConfirmableModal'
import { aiQuestionAtom, INITIAL_AI_QUESTION } from '@/stores'
import { getAuth } from '@/utils/auth'
import { handleError } from '@/utils/httpClient'
import {
  handleRequest,
  handleTokenRefresh,
} from '@/utils/httpClient/handleRequest'
import MaterialSymbol from '@/v1/MaterialSymbol'
import AIAnswerModal from './AIAnswerModal'
import type { BoardCreateForm, BoardCreateResponse } from './types'

/**
 * 질문하기 API 호출
 */
const handleCreate = async (data: BoardCreateForm) => {
  try {
    const response = await handleRequest<BoardCreateResponse>(API_PATH.Qna, {
      method: 'post',
      json: data,
    })
    return response
  } catch (error) {
    throw error
  }
}

/**
 * 질문하기 form submit 및 에러 처리 hook
 * @param handleRetry 엑세스 토큰 갱신 후 다시 시도하는 함수
 * @param reset form reset 함수
 * @returns onSubmit, onError
 */
const useCreateBoard = ({
  handleRetry,
  reset,
}: {
  handleRetry: () => void
  reset: UseFormReset<BoardCreateForm>
}) => {
  const { alertModal, fullScreenModal } = useModal()
  const { modal: fullScreenConfirmableModal } = useFullScreenConfirmableModal()
  const setAiQuestion = useSetAtom(aiQuestionAtom)

  /**
   * 질문하기 form submit 함수
   * - 로그인 상태가 아닌 경우, 토큰 갱신 후 다시 시도
   * - API 호출 성공 시 GTM 이벤트 전송 및 완료 모달 띄우기
   * - API 호출 실패 시 에러 처리 및 form reset
   */
  const onSubmit = async (data: BoardCreateForm) => {
    const { isLoggedIn } = getAuth()
    if (!isLoggedIn) {
      try {
        await handleTokenRefresh()
        handleRetry()
      } catch (error) {
        handleError(error)
      }
      return
    }

    try {
      const { id } = await handleCreate(data)
      // GTM dataLayer에 질문하기 이벤트 전송
      TagManager.dataLayer({
        dataLayer: {
          event: 'create_board',
          contents_id: id,
          cancertype: data.cancer,
        },
      })
      // 작성 완료 시 AI 답변 모달 띄우기
      fullScreenModal.show({
        title: 'AI 답변',
        children: <AIAnswerModal boardId={id} />,
        gaCloseSelector: 'board_create_close',
        onClose: () => {
          alertModal.show({
            message: (
              <div className="flex flex-col justify-center items-center">
                <MaterialSymbol
                  name="info"
                  size={42}
                  className="fill-lavendar-500"
                />
                <div className="prose-p2 mt-4">
                  해당 AI 답변은 <strong>마이케어{'>'}질문함</strong>에서 <br />
                  확인하실 수 있습니다.
                </div>
              </div>
            ),
            confirmButton: {
              text: '확인',
              onClick: fullScreenModal.hide,
            },
          })
        },
      })
    } catch (error) {
      handleError(error)
      reset()
    } finally {
      // 질문작성 모달 닫기
      fullScreenConfirmableModal.hide()
      setAiQuestion(INITIAL_AI_QUESTION) // 초기화
    }
  }

  /**
   * 질문하기 form submit 에러 처리 함수
   * - 암 종류 선택하지 않은 경우, 알림 모달 띄우기
   * - 질문 내용 작성하지 않은 경우, 알림 모달 띄우기
   */
  const onError = (errors: FieldErrors<BoardCreateForm>) => {
    if (errors.cancer) {
      alertModal.show({
        message: MESSAGES.CANCER_TYPE_REQUIRED,
      })
      return
    }

    if (errors.question) {
      alertModal.show({
        message: MESSAGES.CONTENT_REQUIRED,
      })
    }
  }

  return {
    onSubmit,
    onError,
  }
}

export default useCreateBoard
