import { useEffect } from 'react'
import { use100vh } from 'react-div-100vh'
import TagManager from 'react-gtm-module'
import { useForm, Controller, FieldErrors } from 'react-hook-form'
import { useRouter } from 'next/navigation'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { useSWRConfig } from 'swr'
import CancerTypeRadioInputGroup from '@/components/CancerTypeRadioInputGroup'
import Complete from '@/components/Complete'
import {
  QNA_PLACEHOLDER,
  MAX_QNA_CONTENT_SIZE,
  MESSAGES,
  COMPLETE_MAIN_TEXT,
  COMPLETE_SUB_TEXT,
  HEADER_HEIGHT,
  API_PATH,
  KOR_TO_ENG_CANCER_MAP,
  KorCancerType,
  URL_PATH,
} from '@/consts'
import {
  BoardCreateForm,
  BoardCreateResponse,
  boardCreateSchema,
  BoardUpdateForm as BoardUpdateFormType,
} from '@/containers/BoardCreate/types'
import { useModal } from '@/hooks/useModal'
import useFullScreenConfirmableModal, {
  checkCloseConfirmation,
} from '@/hooks/useModal/useFullScreenConfirmableModal'
import { BoardResponse } from '@/types'
import { getAuth } from '@/utils/auth'
import { showErrorAlert } from '@/utils/error'
import { handleCustomError, handleError } from '@/utils/httpClient'
import {
  handleRequest,
  handleTokenRefresh,
} from '@/utils/httpClient/handleRequest'
import LimitedTextarea from '@/v1/LimitedTextarea'

export const handleUpdate = async (id: number, data: BoardUpdateFormType) => {
  try {
    const response = await handleRequest<BoardCreateResponse>(
      `${API_PATH.Qna}${id.toString()}/`,
      {
        method: 'PUT',
        json: data,
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

const BUTTON_HEIGHT = 50

const BoardUpdateForm = ({ data }: { data: BoardResponse }) => {
  const height = use100vh()
  const { id, question, cancer } = data
  const { push } = useRouter()
  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<BoardCreateForm>({
    mode: 'onChange',
    defaultValues: {
      cancer: KOR_TO_ENG_CANCER_MAP[cancer.name as KorCancerType],
      question: question,
    },
    resolver: zodResolver(boardCreateSchema),
    shouldFocusError: false,
  })

  const { alertModal, fullScreenModal } = useModal()
  const { modal: fullScreenConfirmableModal } = useFullScreenConfirmableModal()

  const { mutate } = useSWRConfig()

  function handleRetry() {
    const values = getValues()
    onSubmit(values)
  }

  function onSubmit(data: BoardCreateForm) {
    const { isLoggedIn } = getAuth()
    if (!isLoggedIn) {
      handleTokenRefresh().then(handleRetry).catch(handleError)
      return
    }

    handleUpdate(id, data)
      .then(() => {
        mutate(`${API_PATH.Qna}${id.toString()}/?render_type=html`)

        TagManager.dataLayer({
          dataLayer: {
            event: 'update_board',
            contents_id: id,
            cancertype: data.cancer,
          },
        })

        // 수정 완료 시 완료 모달 띄우기
        fullScreenModal.show({
          title: '질문하기',
          children: (
            <Complete
              type="boardUpdate"
              mainContent={COMPLETE_MAIN_TEXT.boardUpdate}
              subContent={COMPLETE_SUB_TEXT}
              id={id}
            />
          ),
          onClose: () => {
            push(`${URL_PATH.MyCareBoards}${id}/`)
          },
        })
      })
      .catch((error) => {
        handleCustomError({ error, ignoreCodes: [403] }).then(
          ({ code, errorResponse }) => {
            if (code === 403) {
              return showErrorAlert(String(errorResponse), () => {
                push(`${URL_PATH.MyCareBoards}${id}/`)
                fullScreenModal.hide()
              })
            }
          },
        )
      })
      .finally(fullScreenConfirmableModal.hide)
  }

  function onError(errors: FieldErrors<BoardCreateForm>) {
    if (errors.question) {
      alertModal.show({
        message: MESSAGES.CONTENT_REQUIRED,
      })
    }
  }

  // 질문 수정 중에는, 풀스크린 모달 닫기 전 컨펌창으로 확인을 받는다.
  useEffect(() => {
    checkCloseConfirmation(isDirty)
  }, [isDirty])

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div
        style={{
          height: height
            ? height - HEADER_HEIGHT - BUTTON_HEIGHT
            : `calc(100vh - ${HEADER_HEIGHT}px - ${BUTTON_HEIGHT}px`,
        }}
        className="overflow-y-auto scrollbar-hide"
      >
        <div className="flex flex-col">
          <div className={clsx('relative', 'py-6', 'px-md')}>
            <Controller
              render={({ field }) => (
                <>
                  <h2 className="prose-h5 mb-4">질문 내용을 작성해 주세요</h2>
                  <CancerTypeRadioInputGroup
                    register={register}
                    setValue={setValue}
                    defaultValue={
                      KOR_TO_ENG_CANCER_MAP[cancer.name as KorCancerType]
                    }
                  />
                  <LimitedTextarea
                    placeholder={QNA_PLACEHOLDER}
                    maxLength={MAX_QNA_CONTENT_SIZE}
                    className="p-0"
                    {...field}
                  />
                </>
              )}
              name="question"
              control={control}
              defaultValue={question}
            />
          </div>
        </div>
      </div>
      <button
        type="submit"
        disabled={
          // 서버 요청 중이거나
          isSubmitting ||
          // 에러가 있거나
          !!Object.keys(errors).length ||
          // 변경사항이 없으면 비활성화
          !isDirty
        }
        className={clsx(
          'button-fill-primary',
          'button-large',
          'w-full',
          'max-w-[768px]',
          'v1-mobile:max-w-full',
          'rounded-none',
          'fixed',
          'bottom-0',
        )}
        data-ga="board_update_submit"
      >
        수정 완료
      </button>
    </form>
  )
}

export default BoardUpdateForm
