import DeleteButton from '@/containers/MyBoardView/DeleteButton'
import EditButton from '@/containers/MyBoardView/EditButton'
import type { BoardResponse } from '@/types/board'
import LabelBadge from '@/v1/Badge/LabelBadge'
import Content from './Content'
import CreatedDate from './CreatedDate'
import Title from './Title'

/**
 * Q.질문
 * @param tags 태그목록
 * @param question 질문내용
 * @param cancer 암종 객체
 */
const Question = ({
  title,
  board_id: boardId,
  question,
  cancer,
  created_at,
  myQuestion = false,
}: {
  title: BoardResponse['title']
  board_id: BoardResponse['id']
  question: BoardResponse['question']
  cancer: BoardResponse['cancer']['name']
  created_at: BoardResponse['created_at']
  myQuestion?: boolean
}) => {
  if (!boardId) return null

  return (
    <div className="pt-md px-md pb-8 border-b-[10px] border-b-grey-100">
      <div className="mb-2">
        <LabelBadge label={cancer} />
      </div>
      <Title title={title} />
      <CreatedDate createdAt={created_at} />
      <p className="whitespace-pre-line">
        <Content content={question} />
      </p>
      {myQuestion && (
        <div className="flex items-center mt-6">
          <DeleteButton id={`${boardId}`} cancer={cancer} />
          <EditButton id={`${boardId}`} />
        </div>
      )}
    </div>
  )
}

export default Question
