/*
 * 암종 선택 라디오 그룹 + form validation을 위한 hidden input
 */
import { useState, ChangeEvent, useEffect } from 'react'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form'
import HorizontalScrollable from '@/components/HorizontalScrollable'
import { Cancers, KOR_CANCER_TYPES } from '@/consts/cancer'
import { BoardCreateForm } from '@/containers/BoardCreate/types'
import RadioButton from './RadioButton'

type OnSelect = (e: ChangeEvent<HTMLInputElement>) => void

const RADIO_CANCER_TYPES = {
  STOMACH: KOR_CANCER_TYPES.stomach,
  THYROID: KOR_CANCER_TYPES.thyroid,
  LUNG: KOR_CANCER_TYPES.lung,
  COLON: KOR_CANCER_TYPES.colon,
  BREAST: KOR_CANCER_TYPES.breast,
}

const CancerTypeRadio = ({
  label,
  value,
  defaultValue,
  onSelect,
}: {
  label: string
  value: string
  defaultValue: string | undefined
  onSelect: OnSelect
}) => {
  return (
    <div className="mr-2 leading-7" key={`cancerType-${value}`}>
      <RadioButton
        data-ga="board_create_cancertype"
        value={value}
        name="_cancerType"
        defaultChecked={defaultValue === value}
        onChange={(e) => onSelect?.(e)}
        label={label}
      >
        {label}
      </RadioButton>
    </div>
  )
}

const CancerTypeRadioInputGroup = ({
  onSelect,
  defaultValue,
  register,
  setValue,
}: {
  onSelect?: OnSelect
  defaultValue?: Cancers
  register: UseFormRegister<BoardCreateForm>
  setValue: UseFormSetValue<BoardCreateForm>
}) => {
  const [cancerType, setCancerType] = useState(defaultValue)
  const handleCancerTypeSelect = (e: ChangeEvent<HTMLInputElement>) => {
    onSelect?.(e)
    setCancerType(e.target.value as Cancers)
  }

  useEffect(() => {
    setCancerType(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (!cancerType) return
    setValue('cancer', cancerType, { shouldDirty: true, shouldValidate: true })
  }, [cancerType, setValue])

  return (
    <div className="pb-4">
      <HorizontalScrollable>
        <div className="flex w-fit">
          {Object.entries(RADIO_CANCER_TYPES).map(([key, value]) => (
            <CancerTypeRadio
              key={key}
              value={key}
              label={value}
              defaultValue={cancerType}
              onSelect={handleCancerTypeSelect}
            />
          ))}
        </div>
      </HorizontalScrollable>
      <input {...register('cancer')} type="hidden" />
    </div>
  )
}

export default CancerTypeRadioInputGroup
