import Linkify from 'react-linkify'
import type { BoardResponse } from '@/types/board'
import { matchDecorator } from '@/utils/linkify'

export default function Content({
  content,
}: {
  content: BoardResponse['question']
}) {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          key={key}
          className="text-blue-75"
          href={decoratedHref}
          target="blank"
        >
          {decoratedText}
        </a>
      )}
      matchDecorator={matchDecorator}
    >
      {content}
    </Linkify>
  )
}
