import { useSetAtom } from 'jotai'
import { API_PATH } from '@/consts'
import { aiQuestionAtom } from '@/stores'
import { handleError, handleRequest } from '@/utils/httpClient'
import type { AIRecommendationForm, BoardCreateByAIPost } from '../types'

/**
 * AI 질문 요청 hook
 * @returns request: AI 질문 요청 함수
 */
const useAIQuestionRequest = () => {
  const setAiQuestion = useSetAtom(aiQuestionAtom)

  /**
   * AI 질문 요청 함수
   * @param formData
   */
  const request = async (formData: AIRecommendationForm) => {
    try {
      setAiQuestion((prev) => ({
        ...prev,
        state: 'loading',
      }))

      return await handleRequest<BoardCreateByAIPost>(API_PATH.QnaByAI, {
        method: 'post',
        json: {
          cancer: formData.cancer,
          stage: formData.status,
          treatment: formData.treatments,
          ...(formData.interest && { interest: formData.interest }),
        },
      }).then((response) => {
        setAiQuestion({
          state: 'done',
          questions: response.questions,
        })
      })
    } catch (error) {
      handleError(error)
    }
  }

  return {
    request,
  }
}

export default useAIQuestionRequest
