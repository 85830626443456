import { default as NextImage } from 'next/image'
import clsx from 'clsx'
import { DOMNode, domToReact } from 'html-react-parser'
import { S3_IMAGE_URL } from '@/config'

const CHECKPOINT_TYPE = {
  여기서잠깐: '여기서잠깐',
  꼭알아두세요: '꼭알아두세요',
}

// 구버전 마크다운 에디터에서 생성한 aside 태그 렌더링을 위한 컴포넌트
const Aside = ({
  attribs,
  children,
}: {
  attribs: { [name: string]: string }
  children: DOMNode[]
}) => {
  return (
    <div
      className={clsx(
        'py-4',
        'pr-5',
        'pl-3',
        'rounded-[16px]',
        'bg-lavendar-100',
        'my-5',
      )}
    >
      <div className="flex items-center gap-1 pb-1">
        <NextImage
          src={
            attribs['data-type'] === CHECKPOINT_TYPE.여기서잠깐
              ? `${S3_IMAGE_URL}/guide/light.png`
              : `${S3_IMAGE_URL}/guide/index-pointing-up.png`
          }
          alt=""
          width={18}
          height={18}
        />
        <span className="prose-guide-p3 font-bold text-grey-900">
          {attribs['data-type'] === CHECKPOINT_TYPE.여기서잠깐
            ? '여기서 잠깐!'
            : '꼭 알아두세요!'}
        </span>
      </div>
      <div className="pl-[22px] [&_*]:prose-guide-aside">
        {children && <div {...attribs}>{domToReact(children)}</div>}
      </div>
    </div>
  )
}

export default Aside
