import { PropsWithChildren, useEffect, useState, useRef } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import eventEmitter, { EVENT } from '@/utils/eventEmitter'
import { FullPageModalHeaderProps } from '@/v1/Header/FullPageModalHeader'
import FullScreen from '@/v1/Modal/FullScreen'
import ConfirmModal from '../ConfirmModal'

const ModalContent = ({ children }: PropsWithChildren) => {
  return <>{children}</>
}

/**
 * 닫기 버튼 클릭 시, confirm 모달을 띄우는 풀스크린 모달
 * @param title 모달 타이틀
 * @param confirmMessage 닫기 버튼 클릭 시, 띄울 컨펌 메시지
 * @param cancelButtonLabel 취소 버튼 레이블
 * @param confirmButtonLabel 확인 버튼 레이블
 * @param onClose 모달을 닫은 후, 실행할 함수
 * @param onBack 뒤로 가기 버튼 클릭 시 실행할 함수
 * @param gaBackSelector 뒤로 가기 버튼 클릭 시 GA 이벤트 전송 시 사용할 셀렉터
 * @param gaCloseSelector 닫기 버튼 클릭 시 GA 이벤트 전송 시 사용할 셀렉터
 * @param children 모달 내부 컨텐츠
 */
const Modal = NiceModal.create(
  ({
    title,
    confirmMessage,
    cancelButtonLabel = '돌아가기',
    confirmButtonLabel = '종료하기',
    onBack,
    gaCloseSelector,
    gaBackSelector,
    children,
  }: PropsWithChildren<
    FullPageModalHeaderProps & {
      confirmMessage: string
      cancelButtonLabel?: string
      confirmButtonLabel?: string
    }
  >) => {
    const modal = useModal()
    const confirmModal = useModal(ConfirmModal)
    const [shouldConfirm, setShouldConfirm] = useState(false)
    const onCloseRef = useRef<() => void>()

    const handleClose = () => {
      // 컨펌이 필요 없는 경우, 바로 닫기
      if (!shouldConfirm) {
        modal.hide()
        onCloseRef.current?.()
        return
      }

      confirmModal.show({
        message: confirmMessage,
        cancelButton: {
          text: cancelButtonLabel,
          onClick: () => confirmModal.hide(),
        },
        confirmButton: {
          text: confirmButtonLabel,
          onClick: () => {
            confirmModal.hide()
            modal.hide()
            onCloseRef.current?.()
          },
        },
      })
    }

    useEffect(() => {
      const checkConfirmation = ({
        shouldConfirm,
        onClose,
      }: {
        shouldConfirm: boolean
        onClose?: () => void
      }) => {
        setShouldConfirm(shouldConfirm)
        onCloseRef.current = onClose
      }

      eventEmitter.on(EVENT.CHECK_CONFIRMATION, checkConfirmation)

      return () => {
        eventEmitter.off(EVENT.CHECK_CONFIRMATION, checkConfirmation)
      }
    }, [])

    return (
      <FullScreen
        title={title}
        opened={modal.visible}
        closeable={false}
        onBack={onBack}
        onClose={handleClose}
        gaBackSelector={gaBackSelector}
        gaCloseSelector={gaCloseSelector}
        close={true}
      >
        <ModalContent>{children}</ModalContent>
      </FullScreen>
    )
  },
)

export default Modal
