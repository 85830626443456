import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { IconName } from '@/v1/MaterialSymbol/icons'
import { Colors } from './colors'

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  iconName?: IconName
  iconColor?: keyof typeof Colors.icon
  iconBackgroundColor?: keyof typeof Colors.iconBackground
  showChevron?: boolean
}

const Nudge = ({
  iconName,
  iconColor = 'lavendar-500',
  iconBackgroundColor = 'lavendar-100',
  showChevron = true,
  children,
  ...restProps
}: PropsWithChildren<Props>) => {
  return (
    <button
      type="button"
      className={clsx(
        'flex justify-between items-center',
        'w-full',
        'text-left',
        'gap-3 px-6 py-5',
        'rounded-[16px]',
        'bg-white',
        'shadow-[0px_2px_16px_0px_rgba(46,49,52,0.10)]',
        'break-keep',
      )}
      {...restProps}
    >
      <span className="flex justify-between items-center gap-3">
        {iconName && (
          <span
            className={`p-[7px] rounded-[12px] ${Colors.iconBackground[iconBackgroundColor]}`}
          >
            <MaterialSymbol
              name={iconName}
              className={Colors.icon[iconColor]}
              width={30}
              height={30}
            />
          </span>
        )}
        <span>{children}</span>
      </span>
      {showChevron && (
        <MaterialSymbol
          name="chevron_right"
          className="shrink-0 fill-grey-900"
        />
      )}
    </button>
  )
}

export default Nudge
