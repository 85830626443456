import useSWR from 'swr'
import { API_PATH } from '@/consts'
import { BoardResponse } from '@/types'
import BoardUpdateForm from './BoardUpdateForm'

const BoardUpdate = ({ id }: { id: string }) => {
  const { data } = useSWR<BoardResponse>(
    `${API_PATH.Qna}${id}/?render_type=html`,
  )

  if (!data) return null

  return <BoardUpdateForm data={data} />
}

export default BoardUpdate
