import { PropsWithChildren } from 'react'
import { useRouter } from 'next/navigation'
import clsx from 'clsx'
import MaterialSymbol from '../MaterialSymbol'
import TextButton from '../TextButton'
import {
  BACKGROUND_COLOR,
  MessageBoxTypes,
  ICON_COLOR,
  SIZE_CONFIG,
  MessageBoxSizeTypes,
} from './consts'

type ActionLinkProps = {
  text: string
  href: string
  size?: MessageBoxSizeTypes
}

interface Props {
  type?: MessageBoxTypes
  title?: string
  icon?: boolean
  actionLink?: ActionLinkProps
  size?: MessageBoxSizeTypes
}

const ActionLink = ({ text, href, size = 'md' }: ActionLinkProps) => {
  const { push } = useRouter()

  return (
    <div className="w-fit mt-3">
      <TextButton
        type="button"
        size={SIZE_CONFIG[size].actionLinkButton}
        color="plane"
        onClick={() => push(href)}
      >
        {text}
      </TextButton>
    </div>
  )
}

const MessageBox = ({
  type = 'info-blue',
  title,
  icon = true,
  actionLink,
  size = 'md',
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={clsx(
        'flex',
        'p-4',
        'text-grey-800',
        'rounded-[14px]',
        BACKGROUND_COLOR[type],
        'items-start',
        SIZE_CONFIG[size].text,
      )}
    >
      {icon && (
        <MaterialSymbol
          size={SIZE_CONFIG[size].icon}
          name="info_fill"
          className={clsx('mr-2', 'mt-0.5', ICON_COLOR[type])}
        />
      )}
      <div className="w-full">
        {title && (
          <p className="mb-1 text-grey-900">
            <strong>{title}</strong>
          </p>
        )}
        {children}
        {actionLink?.text && actionLink?.href && (
          <ActionLink
            text={actionLink.text}
            href={actionLink.href}
            size={size}
          />
        )}
      </div>
    </div>
  )
}

export default MessageBox
