import { ChangeEventHandler, forwardRef, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { filterPropsWithPrefix } from '@/utils/filterPropsWithPrefix'
import MaterialSymbol from '@/v1/MaterialSymbol'

export type OnChange = ChangeEventHandler<HTMLInputElement>

interface Props {
  name: string
  value: string
  defaultChecked?: boolean
  onChange?: OnChange
  label?: string
  className?: string
}

const BUTTON_COLOR =
  'text-gray-90 border-gray-50 peer-checked:bg-lavendar-100 peer-checked:text-lavendar-700 peer-checked:border-lavendar-100 peer-checked:[&_svg]:inline-block'

/*
 * 암종 선택 라디오 버튼
 * 원래 @/components/RadioButton 컴퍼넌트를 사용하고 있었는데, 피그마에서 이걸 Chip으로 변경했다.
 * 하지만 이건 단일 선택 요소이므로 라디오 버튼이어야 한다. 따라서 컴퍼넌트를 추가해야 하는데, 여기서만 쓰기 때문에 이 파일에 추가한다.
 */
const RadioButton = forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  (
    {
      name = '',
      value,
      defaultChecked = false,
      children,
      onChange,
      label = '',
      className,
      ...restProps
    },
    ref,
  ) => {
    const dataProps = filterPropsWithPrefix(restProps, 'data-')

    return (
      <label>
        <input
          ref={ref}
          type="radio"
          name={name}
          value={value}
          className="peer group hidden"
          defaultChecked={defaultChecked}
          onChange={onChange}
          data-label={label}
          {...restProps}
        />
        <span
          className={clsx(
            'flex',
            'items-center',
            'justify-center',
            'cursor-pointer',
            'select-none',
            'border',
            'border-grey-400',
            'px-3 py-1.5',
            'rounded-[100px]',
            'prose-p3',
            BUTTON_COLOR,
            className,
          )}
          {...dataProps}
        >
          <MaterialSymbol
            name="check"
            className="fill-lavendar-700 mr-2 hidden"
            size={20}
          />
          {children}
        </span>
      </label>
    )
  },
)

RadioButton.displayName = 'RadioButton'
export default RadioButton
