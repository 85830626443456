import clsx from 'clsx'
import Spinner from '@/v1/Spinner'

const Loading = () => {
  return (
    <div
      className={clsx(
        'absolute',
        'inset-0',
        'bg-white',
        'w-full',
        'h-full',
        'z-10',
        'flex',
        'justify-center',
        'align-center',
      )}
    >
      <Spinner size="lg" />
    </div>
  )
}

export default Loading
