import { PropsWithChildren, forwardRef } from 'react'
import clsx from 'clsx'
import { filterPropsWithPrefix } from '@/utils/filterPropsWithPrefix'
import { BUTTON_STYLE, SIZE_CONFIG } from './styles'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'sm' | 'md' | 'lg'
}

const RadioButton = forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  ({ variant = 'sm', children, ...rest }, ref) => {
    const dataProps = filterPropsWithPrefix(rest, 'data-')

    return (
      <label
        className={clsx(
          'inline-block',
          'w-full',
          'select-none',
          rest.className,
        )}
      >
        <input
          type="radio"
          className="peer opacity-0 absolute"
          defaultChecked={rest.defaultChecked}
          name={rest.name}
          value={rest.value}
          onChange={rest.onChange}
          onClick={rest?.onClick}
          disabled={rest.disabled}
          checked={rest.checked}
          {...dataProps}
          ref={ref}
          {...dataProps}
        />
        <span className={clsx(BUTTON_STYLE, SIZE_CONFIG[variant])}>
          {children}
        </span>
      </label>
    )
  },
)

RadioButton.displayName = 'RadioButton'
export default RadioButton
