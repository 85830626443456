import { useState } from 'react'
import TagManager from 'react-gtm-module'
import { useRouter } from 'next/navigation'
import { API_PATH, MESSAGES, KOR_TO_ENG_CANCER_MAP, URL_PATH } from '@/consts'
import { useModal } from '@/hooks/useModal'
import { handleError, handleRequest } from '@/utils/httpClient'
import MaterialSymbol from '@/v1/MaterialSymbol'

const handleDeleteRequest = async (id: string) => {
  try {
    await handleRequest(`${API_PATH.Qna}${id}/`, {
      method: 'DELETE',
    })
  } catch (error) {
    throw error
  }
}

const DeleteButton = ({ id, cancer }: { id: string; cancer: string }) => {
  const { replace } = useRouter()
  const [isDeletedSuccessful, setIsDeletedSuccessful] = useState(false)

  const { confirmModal, alertModal } = useModal()

  const handleDelete = () => {
    handleDeleteRequest(id)
      .then(() => {
        setIsDeletedSuccessful(true)
        TagManager.dataLayer({
          dataLayer: {
            event: 'delete_board',
            contents_id: id,
            cancertype:
              KOR_TO_ENG_CANCER_MAP[
                cancer as keyof typeof KOR_TO_ENG_CANCER_MAP
              ],
          },
        })
        alertModal.show({
          message: MESSAGES.BOARD_DELETED,
          confirmButton: {
            text: '확인',
            onClick: () => replace(URL_PATH.Boards),
          },
        })
      })
      .catch((error) => {
        handleError(error)
        replace(URL_PATH.Boards)
      })
  }

  const handleButtonClick = () => {
    confirmModal.show({
      message: MESSAGES.BOARD_WLL_BE_DELETED,
      confirmButton: {
        text: '삭제하기',
        onClick: handleDelete,
      },
      cancelButton: {
        text: '취소',
      },
    })
  }

  return (
    <div>
      <button
        data-ga="board_document_delete"
        type="button"
        className="flex items-center text-lavendar-500"
        onClick={handleButtonClick}
        disabled={isDeletedSuccessful}
      >
        <MaterialSymbol
          name="delete_forever_fill"
          className="fill-lavendar-500"
          size={16}
        />
        <span className="text-sm ml-1.5">삭제</span>
      </button>
    </div>
  )
}

export default DeleteButton
