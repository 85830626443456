export const MESSAGE_BOX_TYPES = [
  'info-blue',
  'info-grey',
  'warning',
  'error',
] as const

export type MessageBoxTypes = typeof MESSAGE_BOX_TYPES[number]

export const BACKGROUND_COLOR: Record<MessageBoxTypes, string> = {
  'info-blue': 'bg-link-blue-100',
  'info-grey': 'bg-grey-100',
  warning: 'bg-orange-100',
  error: 'bg-red-100',
}

export const ICON_COLOR: Record<MessageBoxTypes, string> = {
  'info-blue': 'fill-link-blue-500',
  'info-grey': 'fill-grey-900',
  warning: 'fill-orange-500',
  error: 'fill-red-500',
}

export const MESSAGE_BOX_SIZE_TYPES = ['sm', 'md'] as const

export type MessageBoxSizeTypes = typeof MESSAGE_BOX_SIZE_TYPES[number]

type SizeConfigType = {
  text: string
  icon: 16 | 20
  actionLinkButton: 'small' | 'medium'
}

export const SIZE_CONFIG: Record<MessageBoxSizeTypes, SizeConfigType> = {
  sm: { text: 'text-sm', icon: 16, actionLinkButton: 'small' },
  md: { text: 'text-base', icon: 20, actionLinkButton: 'medium' },
}
