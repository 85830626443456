import MessageBox from '@/v1/MessageBox'
import AIRecommendations from './AIRecommendations'
import AIRecommendationsForm from './AIRecommendationsForm'

const BoardCreateByAI = () => {
  return (
    <>
      <div className="p-md">
        <MessageBox size="sm">
          건강 정보를 입력해 주시면, AI가 지금 물어보기 좋은 질문을 추천해
          드려요.
        </MessageBox>
        <AIRecommendationsForm />
      </div>
      <AIRecommendations />
    </>
  )
}

export default BoardCreateByAI
