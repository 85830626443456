export const Colors = {
  icon: {
    ['lavendar-500']: 'fill-lavendar-500',
    ['lavendar-100']: 'fill-lavendar-100',
    ['aqua-100']: 'fill-aqua-100',
    ['aqua-500']: 'fill-aqua-500',
  },
  iconBackground: {
    ['lavendar-500']: 'bg-lavendar-500',
    ['lavendar-100']: 'bg-lavendar-100',
    ['aqua-100']: 'bg-aqua-100',
    ['aqua-500']: 'bg-aqua-500',
    ['aqua-800']: 'bg-aqua-800',
  },
}
